"use strict";

angular.module("informaApp")
    .service("AskAnalystSvc", ['BaseApiService', "ConstantsSvc", function (BaseApiService, ConstantsSvc) {
        return {
            sendQuestion: function (question) {
                return BaseApiService.post('ask-analyst/add', question);
            },
            getAllRequests: function () {
                return BaseApiService.get('ask-analyst/all')
            .then(function (response) {
                    return response.data.data;
                });
            },
            changeStatus: function (id, isClosed) {
                return BaseApiService.post('ask-analyst/all', {
                    requestId: id,
                    isClosed: isClosed
                });
            },
            getRequestById: function (id) {
                return BaseApiService.get(`ask-analyst/${id}`)
                .then(function (response) {
                    return response.data.data;
                });
            }
        };
    }]);